<script>
    // <!-- API -->
    import { defineComponent, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';

    // <!-- COMPONENTS -->
    import PageHeader from '@components/PageHeader.vue';

    // <!-- COMPOSABLES -->
    import { useAxios } from '@/plugins/axios';

    // <!-- CONSTANTS -->
    /** Configured Axios instance. */
    const axios = () => useAxios();

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'CartIntegration',
        components: {
            PageHeader,
        },
        setup(props, context) {
            let accountID = ref(useRoute().query);
            const router = useRouter();

            const requestUgradeAccount = async (accountNumber) => {
                const response = await axios().post(
                    `/accounts/${accountNumber}/upgrade`,
                    {
                        account_level: 8, // Valid 5,6,7,8,9
                        account_subscription_length: 2, // Valid 1+
                    }
                );

                completeUpgrade(response);
            };

            const completeUpgrade = async (account) => {
                const response = await axios().put(
                    `/accounts/${accountID.value.acct_id}/upgrade`,
                    {
                        code: account.data.account.upgrade_code, // Upgrade Code
                    }
                );

                // Redirect to the Analysis page.
                router.push('/analysis');
            };

            if (accountID.value.acct_id) {
                requestUgradeAccount(accountID.value.acct_id);
            } else {
                router.push('/signup');
            }

            return { accountID, requestUgradeAccount };
        },
    });
</script>
